import {
  Container,
  Navbar,
  Dropdown,
  Nav,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import Logo from "../../assets/images/shan_and_co_logo.png";
import avtar from "../../assets/images/user-profile.png";
import { GoChevronDown } from "react-icons/go";
import { RiShoppingCartLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { FaRegTrashAlt, FaShoppingCart } from "react-icons/fa";
import notificationIcon from "../../assets/images/notification.svg";
import { useEffect, useRef, useState } from "react";
import WebService from "../../Services/WebService";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoNotificationsOffOutline } from "react-icons/io5";
import {
  USER_INFO,
  USER_LOGOUT,
  setDataInRedux,
} from "../../action/CommonAction";
import PageLoader from "../Common/PageLoader/PageLoader";
import { RootState } from "../../config/Store";
import { reduxState } from "../../reducer/CommonReducer";
import { toast } from "react-toastify";
import HelperService from "../../Services/HelperService";
import LogoutModal from "../Common/Logout/LogoutModal";

const Header = () => {
  const url = WebService.getBaseUrl("wp");
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const commonData: any = useSelector<RootState, reduxState>(
    (state: any) => state.commonData
  );
  const userInfoData: any = useSelector<RootState, any>(
    (state: any) => state.userInfoData
  );
  const [cartData, setCartData] = useState<any[]>([]);
  const [ShowCartLoader, setShowCartLoader] = useState(false);
  const [showLogoutModal, SetShowLogoutModal] = useState<any>(false);
  const [noDataAvailable, SetNoDataAvailable] = useState<any>(false);
  const [count, setCount] = useState<any>();
  const [notificationList, setNotificationList] = useState<any>();
  const [isShowCartDropdown, setShowCartDropdown] = useState(false);

  // useEffect(() => {
  //   userMeCall();
  //   getNotificationList(1);

  //   setInterval(() => {
  //     getNotificationCount();
  //   }, 60000);
  // }, []);



  useEffect(() => {
    const delay = (ms: any) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const fetchData = async () => {
      await delay(2000); // 3 seconds delay
      userMeCall();
      getNotificationList(1);
      getCartProduct();
      getNotificationCount();
    };

    fetchData();
    setInterval(() => {
      getNotificationCount();
    }, 60000);
  }, []);

  useEffect(() => {

    if(commonData?.update_cart){
      
      getCartProduct();
    }
  }, [commonData?.update_cart]);

  const userMeCall = async () => {
    WebService.getAPI({ action: "me", id: "login_btn" })
      .then((res: any) => {
        dispatch(setDataInRedux({ type: USER_INFO, value: res }));
      })
      .catch(() => {
        typeof window !== "undefined" && localStorage.clear();
      });
  };

  const onLogout = () => {
    WebService.postAPI({ action: "users/log-out", body: {}, id: "logoutBtn" })
      .then((res: any) => {
        localStorage.clear();
        window.location.href = url;
      })
      .catch((e) => {
        localStorage.clear();
        window.location.href = url;
      });
    dispatch(setDataInRedux({ type: USER_LOGOUT, value: "" }));
  };

  const getCartProduct = () => {
    setShowCartLoader(true);
    SetNoDataAvailable(false);
    WebService.getAPI({
      action: `cart-details`,
      body: null,
    })
      .then((res: any) => {
        setShowCartLoader(false);
        setCartData(res);
        if (res && res.length === 0) {
          SetNoDataAvailable(true);
        }
      })
      .catch((e) => {
        setShowCartLoader(false);
        SetNoDataAvailable(true);
      });
  };

  const removeCartProduct = (id: string) => {
    setShowCartLoader(true);
    WebService.deleteAPI({
      action: `remove-from-cart/${id}`,
      body: null,
    })
      .then((res: any) => {
        setShowCartLoader(false);
        toast.success(res.message);
        getCartProduct();
      })
      .catch((e) => {
        setShowCartLoader(false);
      });
  };

  const getNotificationCount = () => {
    WebService.getAPI({
      action: `notification-count`,
    }).then((res: any) => {
      setCount(res.count);
    });
  };

  const getNotificationList = (page: any) => {
    WebService.getAPI({
      action: `notification-list/${page}`,
    })
      .then((res: any) => {
        setCount(res.count);
        setNotificationList(res.list);
      })
      .catch(() => {});
  };

  const toggleCartDropdown = () => {
    setShowCartDropdown(!isShowCartDropdown);
  };
  const closeCartDropdown = () => {
    setShowCartDropdown(false);
  };
  const [isshowProfileDropdown, setShowProfileDropdown] = useState(false);

  const toggleProfileDropdown = () =>
    setShowProfileDropdown(!isshowProfileDropdown);
  const closeProfileDropdown = () => setShowProfileDropdown(false);

  return (
    <>
      <header className="site-header">
        <Container fluid className="header-nav py-2">
          <Navbar expand="lg">
            <Navbar.Brand className="me-5">
              <Link to={WebService.getBaseUrl("wp")}>
                <img src={Logo} width={186} alt="" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <div className="d-flex align-items-center ms-auto gap-3">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 site-nav">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {" "}
                    Solutions{" "}
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${url}hire-globally/`}
                      >
                        Hiring
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href={`${url}compliance/`}>
                        Compliance
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${url}global-organization/`}
                      >
                        Documentation
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Documents
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle dropdown-item"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Compliance
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}compliance-calendar/`}
                          >
                            Compliance-Calendar
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}data-protection-regulations/`}
                          >
                            Data Protection Regulations
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}entity-constitution-documents/`}
                          >
                            Entity Constitution Documents
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}corporate-governance/`}
                          >
                            Corporate Governance
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}indirect-taxes-guide/`}
                          >
                            Indirect Taxes Guide
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}entity-closure-options/`}
                          >
                            Entity Closure Options
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}legal-entity-set-up-options/`}
                          >
                            Legal Entity Set Up Options
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle dropdown-item"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Employment
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}employee-benefits/`}
                          >
                            Employee Benefits
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}employment-contracts/`}
                          >
                            Employment Contracts
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}employment-law-guide/`}
                          >
                            Employment Law Guide
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}salary-cost-playbook/`}
                          >
                            Salary Cost Playbook
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}employee-salary-slip/`}
                          >
                            Employee Salary Slip
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}payroll-social-security-issues/`}
                          >
                            Payroll & Social Security Issues
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}stock-option-schemes-equity-compensation/`}
                          >
                            Stock Option Schemes & Equity Compensation
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}other-documents/`}
                          >
                            Other Documents
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle dropdown-item"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Organization
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}commercial-leases/`}
                          >
                            Commercial Leases
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}inter-company-agreements/`}
                          >
                            Inter Company Agreements
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}customer-invoicing-requirements/`}
                          >
                            Customer Invoicing Requirements
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}translated-government-forms/`}
                          >
                            Translated Government Forms
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}country-cost-playbook/`}
                          >
                            Country Cost Playbook
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}public-holidays/`}
                          >
                            Public Holidays
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}global-updates/`}
                          >
                            Global Updates
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={`${url}other-documents/`}
                          >
                            Other Documents
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={`${url}pricing/`}>
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={`${url}how-it-works/`}>
                    How It Works
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={`${url}news/`}>
                    News
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={`${url}about/`}>
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://nucleus-co.com/contact-us/"
                    target="_blank"
                  >
                    Help
                  </a>
                </li>
              </ul>
              <Link to="/catalog" className="btn btn-light py-2 px-4">
                Catalog
              </Link>

              <Dropdown
                className="profile-dd"
                show={isshowProfileDropdown}
                onToggle={toggleProfileDropdown}
              >
                <Dropdown.Toggle onClick={toggleProfileDropdown}>
                  {userInfoData &&
                    userInfoData?.user_info &&
                    !HelperService.isEmptyObject(userInfoData?.user_info) && (
                      <div className="d-flex gap-2 align-items-center">
                        <div>
                          <img
                            src={
                              userInfoData?.user_info?.avtar
                                ? userInfoData?.user_info?.avtar
                                : avtar
                            }
                            width={35}
                            height={35}
                            className=" rounded-circle object-fit-cover"
                            alt=""
                          />
                        </div>
                        <div>
                          <p className="font-14 mb-0 text-white font-medium">
                            {userInfoData?.user_info?.first_name}{" "}
                            {userInfoData?.user_info?.last_name}
                          </p>
                        </div>
                      </div>
                    )}

                  <GoChevronDown size={16} className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {!userInfoData?.user_info?.is_staff && (
                    <>
                      <Link
                        to="/dashboard"
                        className=" dropdown-item"
                        onClick={closeProfileDropdown}
                      >
                        My Dashboard
                      </Link>
                      <Link
                        to="/subscriptions"
                        className=" dropdown-item"
                        onClick={closeProfileDropdown}
                      >
                        My Subscriptions
                      </Link>
                    </>
                  )}

                  <Link
                    to="/profile"
                    className=" dropdown-item"
                    onClick={closeProfileDropdown}
                  >
                    My Account
                  </Link>
                  <Button
                    onClick={() => SetShowLogoutModal(true)}
                    className=" dropdown-item"
                  >
                    Logout
                  </Button>
                </Dropdown.Menu>
              </Dropdown>
              {/* ! add */}

              {/* notification */}

              {!userInfoData?.user_info?.is_staff && (
                <Dropdown className="cart-dd">
                  <Dropdown.Toggle>
                    <div className="d-flex gap-2">
                      <div>
                        <IoNotificationsOutline size={18} />

                        {count > 0 && (
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger mt-2 ">
                            {count}
                          </span>
                        )}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="mt-2">
                    {notificationList && notificationList.length > 0 ? (
                      notificationList.map((item: any, index: any) => (
                        <>
                          <div className="mt-1">
                            <div>
                              <b>{item?.title ? item?.title : " "}</b>
                            </div>
                            <span className="text-muted">
                              {item.decription ? item.decription : " "}
                            </span>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="text-center mb-4">
                        <IoNotificationsOffOutline
                          style={{ fontSize: "25px" }}
                        />
                        <span
                          className="ms-3 mt-2"
                          style={{ fontSize: "20px" }}
                        >
                          No Notification Available
                        </span>
                      </div>
                    )}

                    {notificationList && notificationList.length > 0 ? (
                      <>
                        <div className="d-flex justify-content-between pt-3 border-top">
                          <Link to="/notification" className="btn btn-brand-1">
                            View All
                          </Link>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {!userInfoData?.user_info?.is_staff && (
             <Dropdown
             className="cart-dd"
             show={isShowCartDropdown}
             onToggle={toggleCartDropdown}
           >
             <Dropdown.Toggle onClick={toggleCartDropdown}>
               <div className="d-flex gap-2">
                 <div>
                   <RiShoppingCartLine size={18} />{" "}
                   {cartData && cartData.length > 0 && <span>{cartData.length}</span>}
                 </div>
               </div>
               <GoChevronDown size={16} className="icon ms-1" />
             </Dropdown.Toggle>
             <Dropdown.Menu className="dropdown-menu-scrollable" align="end">
               {!ShowCartLoader && cartData && cartData.length > 0 ? (
                 <table width="100%" className="table table-borderless">
                   {cartData.map((item: any, i: number) => (
                     <tr key={i}  className="border-top">
                       <td className="font-medium ">
                         <Link to="#" className="text-brand">
                           {item.name}
                         </Link>
                       </td>
                       <td>
                         {item.sale_price &&
                           `$${HelperService.formateDecimal(item.sale_price)}`}
                       </td>
                       <td>
                         <a
                           href="#"
                           className="text-secondary"
                           onClick={() => removeCartProduct(item.id)}
                         >
                           <FaRegTrashAlt />
                         </a>
                       </td>
                     </tr>
                   ))}
                 </table>
               ) : (
                 <div>
                   {noDataAvailable && (
                     <div className="text-center mb-4">
                       <FaShoppingCart style={{ fontSize: "35px" }} />
                       <span className="ms-3 mt-1" style={{ fontSize: "30px" }}>
                         No Item in Cart
                       </span>
                     </div>
                   )}
                 </div>
               )}
               {ShowCartLoader && <PageLoader />}
               <div className="d-flex justify-content-between pt-3 border-top">
                 <Link to="/cart" className="btn btn-brand-1" onClick={closeCartDropdown}>
                   View Cart
                 </Link>
                 <Link
                   to="/checkout"
                   className="btn btn-outline-brand"
                   onClick={closeCartDropdown}
                 >
                   Checkout
                 </Link>
               </div>
             </Dropdown.Menu>
             
           </Dropdown>
           
              )}
            </div>
            </Navbar.Collapse>
          </Navbar>
        </Container>

        <LogoutModal
          close={() => SetShowLogoutModal(false)}
          onSuccess={onLogout}
          isShow={showLogoutModal}
        />
      </header>
    </>
  );
};
export default Header;
